import React, { useEffect, useMemo, useState } from 'react'
import { Tabs, TabsList, TabsTrigger } from '../../Common/ui/tabs'
import { Button } from '../../Common/ui/button';
import IotModel from './DashboardModal';
import { useForm, useWatch } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../Common/ui/form';
import { AlertCircle, ChevronLeft, Edit, EllipsisVertical, Filter, Layout, LoaderCircle, Plus, Settings } from 'lucide-react';
import { Input, InputBlock } from '../../Common/ui/input';
import { default as ReactMultiSelector } from 'react-select';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../../Common/ui/select';
import { RadioGroup, RadioGroupItem } from '../../Common/ui/radio-group';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import IOTCard from './DashBoardCard';
import { getConfigurationDashDashboardCard, getConfigurationDashboardTab, getSlaveDataByGroupId, getSlaveGroups, InsertConfigurationDashboardCard, insertConfigurationDashboardTab, UpdateConfigurationDashboardCard, UpdateConfigurationDashboardTab, IndividualCardRefresh, getRolesById, getUsersList, getTabsPermissionIds, getCardsPermissionIds, getTabsPermissionByUserId, getCardsPermissionByUserId, getmeter_type, getDriveByMeterTypeId, getCategoryParameters, getmeter_category } from '../../Common/Utils';
import { toast } from 'react-toastify';
import DotLoader from '../../Common/Loaders/DotLoader';
import { Textarea } from '../../Common/ui/textarea';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuTrigger } from "../../Common/ui/dropdown-menu";
import './Dashboard.css'
import { Modal } from 'react-bootstrap';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from '../../Common/ui/table';
import GoogleLoader from '../../Common/Loaders/GoogleLoader';
import { ScrollArea } from '../../Common/ui/scroll-area';
import { BootstrapTooltip } from '../../Common/common';
import { cn } from '../../lib/utils';
import SlaveConsumptions from './components/slaveConsumptions';



// Define the validation schema
const schema = yup.object().shape({
    tab_name: yup.string().required('Tab Name is required').min(3, 'Tab Name must be at least 3 characters'),
    tab_description: yup.string().max(500, 'Description must not exceed 500 characters'),
    view_permission: yup.array().min(1, 'At least one View Permission must be selected').required('View Permission is required'),
});

// Define the validation schema
const Cardschema = yup.object().shape({
    card_name: yup.string().required('Card Name is required'),
    card_description: yup.string(),
    cardView: yup.string(),
    cardColor: yup.string(),
    slave_type_id: yup.string().required('Slave Type is required'),
    group_id: yup.array().min(1, 'At least one Group must be selected').required('Group is required'),
    slaveType: yup.string().oneOf(['Addition', 'Substraction']).required('Slave Type is required'),
    slave_category: yup.string().required('Salve Category is required'),
    addition_slave_list: yup.array().when('slaveType', {
        is: 'Addition',
        then: (schema) => schema.min(1, 'At least one addition slave must be selected').required('Addition Slave List is required'),
        otherwise: (schema) => schema
    }),
    view_permission: yup.array().min(1, 'At least one View Permission must be selected').required('View Permission is required'),
});



export default function IOTDashboard() {

    const queryClient = useQueryClient();
    const [currentTabs, setCurrentTabs] = useState(null)
    const [showTabModal, setShowTabModal] = useState(false);
    const [isTabEdit, setIsTabEdit] = useState(false);
    const [status, setStatus] = useState("1");
    const [Tabstatus, setTabStatus] = useState("1");
    const [isTabshow, setisTabshow] = useState(false)
    const [RefreshIndi, setRefreshIndi] = useState(false)
    const [RefreshId, setRefreshId] = useState(null)
    const [isTableInfoModalOpen, setIsTableInfoModalOpen] = useState(false)
    const [activeTab, setActiveTab] = useState("available")
    const [FoundData, setFoundData] = useState([])
    const [NotFoundData, setNotFoundData] = useState([])
    const [UnavailableData, setUnavailableData] = useState([])
    const [totalData, settotalData] = useState({})
    const [group_idData, setgroup_idData] = useState([])
    const [permision, setpermision] = useState([])
    const [tabs_id, settabs_id] = useState([])
    const [cards_id, setcards_id] = useState([])
    const [showAddCardModal, setShowAddCardModal] = useState(false);
    const [isCardEdit, setIsCardEdit] = useState(false);
    const [SlaveDriverType, setSlaveDriverType] = useState(null)
    const [InfoslavTypeId, setInfoslavTypeId] = useState(null)
    const [CategoryId, setCategoryId] = useState(null)
    const [CategoryPerameter, setCategoryPerameter] = useState([])
    const [consumptionModel, setconsumptionModel] = useState({ isOpen: false, cardId: null , headerName: null}) 
    const userInfo = JSON.parse(window.localStorage.getItem('user_info'))

    const addCardForm = useForm({
        resolver: yupResolver(Cardschema),
        defaultValues: {
            card_name: '',
            card_description: '',
            multification_fector: '',
            cardView: 'List View',
            cardColor: '#ffffff',
            group_id: [],
            slaveType: 'Addition',
            addition_slave_list: [],
            substraction_slave_list: [],
            view_permission: [],
            slave_category: null
        },
    });
    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            tab_name: '',
            tab_description: '',
            view_permission: [],
        },
    });

    const slave_category = useWatch({
        control: addCardForm.control,
        name: 'slave_category',
    });

    const slave_type_id = useWatch({
        control: addCardForm.control,
        name: 'slave_type_id',
    });

    const group_ids = useWatch({
        control: addCardForm.control,
        name: 'group_id',
    });

    const addition_slave_list = useWatch({
        control: addCardForm.control,
        name: 'addition_slave_list',
    });

    const substraction_slave_list = useWatch({
        control: addCardForm.control,
        name: 'substraction_slave_list',
    });

    const Edit_tab_ids = useWatch({
        control: form.control,
        name: 'id',
    });

    const Edit_card_ids = useWatch({
        control: addCardForm.control,
        name: 'cardId',
    });

    useEffect(() => {
        setgroup_idData(group_ids)
    }, [group_ids])

    const slaveTypeValue = useWatch({
        control: addCardForm.control,
        name: 'slaveType',
    });

    // fetch Tabs DATA
    const {
        data: TabsData,
        isLoading: isTabsLoading,
        error: TabsError,
    } = useQuery({
        queryKey: ["Conf-Tabs", Tabstatus, tabs_id, userInfo?.role],
        queryFn: () => getConfigurationDashboardTab(Tabstatus, JSON.stringify(tabs_id), userInfo?.role),
        enabled: tabs_id?.length > 0 || userInfo?.role === 'ROLE1650614500708'
    });

    const {
        data: SlaveCategoryData,
        error: SlaveCategoryError,
    } = useQuery({
        queryKey: ["getmeter_category"],
        queryFn: getmeter_category,
    });

    const {
        data: CategoryPerameterData,
        isLoading: isCategoryPerameterLoading,
        error: CategoryPerameterError,
    } = useQuery({
        queryKey: ["CategoryPerameter", CategoryId],
        queryFn: () => getCategoryParameters({ tableName: `${CategoryId}_parameters_index` }),
        enabled: !!CategoryId
    });



    const {
        data: TabPermissionUserData,
        isLoading: isTabPermissionUserDataLoading,
        error: TabPermissionUserDataError,
    } = useQuery({
        queryKey: ["TabPermissionUser"],
        queryFn: () => getTabsPermissionByUserId(userInfo?.unique_id),
        enabled: userInfo?.role !== 'ROLE1650614500708'
    });

    const {
        data: SlaveTypesData,
        error: SlaveTypesError,
    } = useQuery({
        queryKey: ["getmeter_type"],
        queryFn: getmeter_type,
    });

    const {
        data: allDriverData,
        error: allDriverError,
    } = useQuery({
        queryKey: ["getDriveByMeterTypeId", slave_type_id, InfoslavTypeId, slave_category, totalData?.slave_category],
        queryFn: () => getDriveByMeterTypeId({ slave_type_id: slave_type_id || InfoslavTypeId, slave_category: slave_category || totalData?.slave_category }),
        enabled: ((!!slave_type_id || !!InfoslavTypeId) && (!!slave_category || !!totalData?.slave_category))
    });

    useMemo(() => {
        // console.log('TabPermissionUserData',TabPermissionUserData)
        settabs_id(TabPermissionUserData?.data?.data?.map((item) => (item?.tab_id)))
    }, [TabPermissionUserData])

    useMemo(() => {
        // console.log('CategoryPerameterData',CategoryPerameterData)
        setCategoryPerameter(CategoryPerameterData?.data?.data)
    }, [CategoryPerameterData])

    const {
        data: CardPermissionUserData,
        isLoading: isCardPermissionUserDataLoading,
        error: CardPermissionUserDataError,
    } = useQuery({
        queryKey: ["CardPermissionUser"],
        queryFn: () => getCardsPermissionByUserId(userInfo?.unique_id),
        enabled: userInfo?.role !== 'ROLE1650614500708'
    });

    useMemo(() => {
        setcards_id(CardPermissionUserData?.data?.data?.map((item) => (item?.card_id)))
    }, [CardPermissionUserData])

    useMemo(() => {
        setSlaveDriverType(allDriverData?.data?.data?.map((item) => (item?.slave_unique_id)))
    }, [allDriverData])



    // fetch users DATA
    const {
        data: UsersData,
        isLoading: isUserLoading,
        error: UsersError,
    } = useQuery({
        queryKey: ["Conf-Users"],
        queryFn: () => getUsersList()
    });


    // fetch getSlaveGroups
    const {
        data: SlaveGroupsData,
        error: SlaveGroupsError,
    } = useQuery({
        queryKey: ["getSlaveGroups"],
        queryFn: getSlaveGroups,
    });

    // fetch getSlaveGroups
    const {
        data: SlaveDataByGroupId,
        error: SlaveDataByGroupIdError,
    } = useQuery({
        queryKey: ["getSlaveGroups", group_idData, SlaveDriverType],
        queryFn: () => getSlaveDataByGroupId({ group_id: group_idData, SlaveDriverType: SlaveDriverType }),
        enabled: group_idData?.length > 0 && !!SlaveDriverType
    });

    const handleTableCardInfoModal = () => {
        setIsTableInfoModalOpen(false)
        setgroup_idData([])
        setInfoslavTypeId(null)
    }

    const handleModalOpen = (result_data) => {
        // console.log({ result_data })
        setgroup_idData(JSON.parse(result_data?.group_id || '[]'))
        setInfoslavTypeId(result_data?.slave_type_id)
        setCategoryId(result_data?.slave_category)
        setIsTableInfoModalOpen(true)
        setNotFoundData(Array.from(new Set(JSON.parse(result_data.data_found_slave || '[]').filter(info => JSON.parse(result_data?.substraction_slave_list || '[]').includes(info.unique_id)).map(JSON.stringify))).map(JSON.parse))
        setFoundData(Array.from(new Set(JSON.parse(result_data.data_found_slave || '[]').filter(info => JSON.parse(result_data?.addition_slave_list || '[]').includes(info.unique_id)).map(JSON.stringify))).map(JSON.parse))
        setUnavailableData(JSON.parse(result_data.data_not_found_slave || '[]'))
        settotalData(result_data)
    }


    // fetch card Data by Tab ID
    const {
        data: cardData,
        isLoading: isCardDataLoading,
        error: CardError,
    } = useQuery({
        queryKey: ["Card-Data", currentTabs, cards_id, status],
        queryFn: () => getConfigurationDashDashboardCard(currentTabs, status, JSON.stringify(cards_id), userInfo?.role),
        enabled: (!!currentTabs && cards_id?.length > 0) || userInfo?.role === 'ROLE1650614500708',
        refetchInterval: 30000
    });

    const {
        data: TabUserData,
        isLoading: isTabUserLoading,
        error: TabUserError,
    } = useQuery({
        queryKey: ["Tabs_User"],
        queryFn: () => getTabsPermissionIds(Edit_tab_ids),
        enabled: !!Edit_tab_ids
    });

    useMemo(() => {
        // console.log(TabUserData)
        if (showTabModal && Edit_tab_ids) {
            form.setValue('view_permission', TabUserData?.data?.data?.map(item => item?.user_id) || [])
        }
    }, [TabUserData, showTabModal])

    const {
        data: CardUserData,
        isLoading: isCardUserLoading,
        error: CardUserError,
    } = useQuery({
        queryKey: ["Tabs_User"],
        queryFn: () => getCardsPermissionIds(Edit_card_ids),
        enabled: !!Edit_card_ids
    });

    useEffect(() => {
        // console.log('CardUserData', CardUserData)
        if (showAddCardModal && Edit_card_ids) {
            addCardForm.setValue('view_permission', CardUserData?.data?.data?.map(item => item?.user_id) || [])
        }
    }, [CardUserData, showAddCardModal])

    // add useEffect for track error 
    useEffect(() => {
        if (TabsError) {
            toast.error(`Error fetching accounts: ${JSON.stringify(TabsError)}`);
        }
        if (SlaveGroupsError) {
            toast.error(`Error fetching SlaveGroups: ${JSON.stringify(SlaveGroupsError)}`);
        }
        if (SlaveDataByGroupIdError) {
            toast.error(`Error fetching Slave Data By GroupId: ${JSON.stringify(SlaveDataByGroupIdError)}`);
        }
        if (CardError) {
            toast.error(`Error fetching Slave Data By CardError: ${JSON.stringify(CardError)}`);
        }
        if (SlaveTypesError) {
            toast.error(`Error fetching Slave Data By SlaveTypesError: ${JSON.stringify(SlaveTypesError)}`);
        }
        if (allDriverError) {
            toast.error(`Error fetching Slave Data By AllDriverError: ${JSON.stringify(allDriverError)}`);
        }
        if (SlaveCategoryError) {
            toast.error(`Error fetching Slave Data By SlaveCategoryError: ${JSON.stringify(SlaveCategoryError)}`);
        }
    }, [TabsError, SlaveGroupsError, SlaveDataByGroupIdError, CardError, SlaveTypesError, allDriverError])

    const tabsOptions = useMemo(() => {
        if (!TabsData?.data?.data) return [];
        const data = TabsData?.data?.data.map(({ TAB_NAME, UNIQUE_ID }) => ({
            value: UNIQUE_ID,
            label: TAB_NAME,
        }))
        setCurrentTabs(currentTabs || data[0]?.value)
        return data
    }, [TabsData]);

    const UsersOptions = useMemo(() => {
        if (!UsersData?.data?.data) return [];
        const data = UsersData?.data?.data.map(({ label, value }) => ({
            value: value,
            label: label,
        }))
        return data
    }, [UsersData]);

    const salveTypeOptions = useMemo(() => {
        if (!SlaveTypesData?.data?.data) return [];

        return SlaveTypesData?.data?.data.map(({ METER_TYPE_NAME, UNIQUE_ID }) => ({
            value: UNIQUE_ID,
            label: METER_TYPE_NAME,
        }))
    }, [SlaveTypesData]);

    const onHandaleRoles = async () => {
        const data = await getRolesById(userInfo?.role)
        if (data.success === true) {
            const obj = data.data.data[0]
            setpermision({
                ...obj,
                roles_permission_category0: JSON.parse(
                    obj.roles_permission_category0 || '{}'
                ),
                roles_permission_category5: JSON.parse(
                    obj.roles_permission_category5 || '{}'
                ),
                roles_permission_category2: JSON.parse(
                    obj.roles_permission_category2 || '{}'
                ),
                roles_permission_category3: JSON.parse(
                    obj.roles_permission_category3 || '{}'
                ),
                roles_permission_category1: JSON.parse(
                    obj.roles_permission_category1 || '{}'
                ),
                roles_permission_category4: JSON.parse(
                    obj.roles_permission_category4 || '{}'
                ),
                roles_other_permission: JSON.parse(
                    obj.roles_other_permission || '{}'
                ),
            })
        } else {
            console.log('something want wrong')
        }
    }



    useEffect(() => {
        onHandaleRoles()
    }, [])

    const SlaveOptions = useMemo(() => {
        if (!SlaveGroupsData?.data?.data) return [];
        return SlaveGroupsData?.data?.data.map(({ group_name, unique_id }) => ({
            value: unique_id,
            label: group_name,
        }))

    }, [SlaveGroupsData]);

    const SlaveDataByGroupIdOptions = useMemo(() => {
        if (!SlaveDataByGroupId?.data?.data) return [];
        const slaves_data = SlaveDataByGroupId?.data?.data.filter((v) => v?.slave_status === 1).map(({ unique_id, feeder_name, slave_address }) => ({
            value: unique_id,
            label: `${feeder_name || ""} (${slave_address})`,
            slave_name: feeder_name,
            slave_address: slave_address
        }))
        // console.log('addition_slave_list', addition_slave_list, slaves_data, slaves_data.filter((slaveInfo) => (addition_slave_list.includes(slaveInfo?.value))).map(item => item?.value));
        addCardForm.setValue('addition_slave_list', slaves_data.filter((slaveInfo) => (addition_slave_list.includes(slaveInfo?.value))).map(item => item?.value))
        addCardForm.setValue('substraction_slave_list', slaves_data.filter((slaveInfo) => (substraction_slave_list.includes(slaveInfo?.value))).map(item => item?.value))
        return slaves_data

    }, [SlaveDataByGroupId]);

    const salveCategoryOptions = useMemo(() => {
        if (!SlaveCategoryData?.data?.data) return [];

        return SlaveCategoryData?.data?.data.map(({ METER_CATEGORY_NAME, UNIQUE_ID }) => ({
            value: UNIQUE_ID,
            label: METER_CATEGORY_NAME,
        }))
    }, [SlaveCategoryData]);

    const cardDataArray = useMemo(() => {
        if (!cardData?.data?.data) return [];
        return cardData?.data?.data.map((v) => v)

    }, [cardData]);

    useEffect(() => {
        setCurrentTabs(tabsOptions[0]?.value)
    }, [Tabstatus])


    const statusOption = [
        { value: "1", label: 'Active' },
        { value: "0", label: 'InActive' },
    ];



    const statusFilterOptions = [
        { value: null, label: "All" },
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" }
    ];


    //form for tabs


    //handle edit Tab Name
    const HandleTabEdit = async (value) => {
        if (permision?.roles_other_permission?.GSM_DASH_TAB?.edit == true || userInfo?.role === 'ROLE1650614500708') {
            setShowTabModal(true)
            setIsTabEdit(true)
            const editData = TabsData?.data?.data?.find((v) => v?.UNIQUE_ID === value)
            // console.log(editData)
            form.reset({
                tab_name: editData?.TAB_NAME,
                tab_description: editData?.TAB_DESCRIPTION,
                status: editData?.STATUS.toString(),
                id: editData?.UNIQUE_ID,
                view_permission: []
            });
        } else {
            return toast.error(`You Don't have permission Edit Tab`)
        }
    }

    //handle tab add model 
    const HandleTabCloseFilter = async () => {
        setShowTabModal(false)
        setIsTabEdit(false)
        form.reset({
            tab_name: '',
            tab_description: '',
        });
    }

    const handleSubmit = (data) => {
        if (isTabEdit) {
            updateTabMutation.mutate(data)
        } else {
            createTabMutation.mutate(data)
        }

    };


    //current Tabs Chnage handler
    const handleTabChange = (tab) => {
        setCurrentTabs(tab);
        setisTabshow(false)
    }



    //Handle Card Edit
    const HandleEditCard = (id) => {
        if (permision?.roles_other_permission?.GSM_DASH_CARD?.add == true || userInfo?.role === 'ROLE1650614500708') {
            const editData = cardData?.data?.data?.find((v) => v?.unique_id === id)
            addCardForm.reset({
                card_name: editData?.card_name,
                card_description: editData?.card_description,
                multification_fector: editData?.multification_fector,
                slave_type_id: editData?.slave_type_id,
                cardView: editData?.cardView,
                cardColor: editData?.cardColor,
                group_id: JSON.parse(editData?.group_id),
                slaveType: 'Addition',
                addition_slave_list: JSON.parse(editData?.addition_slave_list),
                substraction_slave_list: JSON.parse(editData?.substraction_slave_list),
                status: editData?.status.toString(),
                cardId: editData?.unique_id,
                slave_category: editData?.slave_category
            });
            setIsCardEdit(true);
            setShowAddCardModal(true)
        } else {
            return toast.error(`You Don't have permission Edit Card`)
        }
    }

    const onRefreshBtn = (id) => {
        setRefreshIndi(true)
        setRefreshId(id)
        onIndividualCardRefresh.mutate(id)
    }


    //handle card add model 
    const handleAddCardSubmit = (data) => {
        if (isCardEdit) {
            updateCardMutation.mutate({ ...data, tab_id: currentTabs })
        } else {
            createCardMutation.mutate({ ...data, tab_id: currentTabs })
        }

    };

    //handle tab add model 
    const HandleCardCloseFilter = async () => {
        setIsCardEdit(false);
        setShowAddCardModal(false)
        addCardForm.reset({
            card_name: '',
            card_description: '',
            multification_fector: '',
            cardView: 'List View',
            cardColor: '#ffffff',
            group_id: [],
            slaveType: 'Addition',
            addition_slave_list: [],
            substraction_slave_list: [],
            view_permission: [],
            slave_type_id: null,
            slave_category: null
        });
    }

    // function for add tab
    const createTabMutation = useMutation({
        mutationFn: insertConfigurationDashboardTab,
        onSuccess: (e) => {
            toast.success('Tab Added successfully');
            HandleTabCloseFilter();
            queryClient.invalidateQueries({ queryKey: ['Conf-Tabs'] });
        },
        onError: (error) => {
            console.error('Error During Add  Tab', error);
            toast.error(`Failed to Add Tab: ${error}`)
        }
    });

    // function for update tab
    const updateTabMutation = useMutation({
        mutationFn: UpdateConfigurationDashboardTab,
        onSuccess: () => {
            HandleTabCloseFilter();
            toast.success('Tab updated successfully');
            queryClient.invalidateQueries({ queryKey: ['Conf-Tabs'] });
        },
        onError: (error) => {
            console.log("Error During Tab Update: ", error);
            toast.error(`Failed to add Tab: ${error}`)
        }
    });


    // function for add Card
    const createCardMutation = useMutation({
        mutationFn: InsertConfigurationDashboardCard,
        onSuccess: (e) => {
            toast.success('Card Added successfully');
            HandleCardCloseFilter();
            queryClient.invalidateQueries({ queryKey: ['Card-Data'] });
        },
        onError: (error) => {
            console.error('Error During Add  Card', error);
            toast.error(`Failed to Add Card: ${error?.response?.data?.message}`)
        }
    });

    // function for update Card
    const updateCardMutation = useMutation({
        mutationFn: UpdateConfigurationDashboardCard,
        onSuccess: () => {
            HandleCardCloseFilter();
            toast.success('Card updated successfully');
            queryClient.invalidateQueries({ queryKey: ['Card-Data'] });
        },
        onError: (error) => {
            console.log("Error During Card Update: ", error);
            toast.error(`Failed to Update Card: ${error}`)
        }
    });

    const onIndividualCardRefresh = useMutation({
        mutationFn: IndividualCardRefresh,
        onSuccess: () => {
            ;
            toast.success('Card Refresh successfully');
            setRefreshIndi(false)
            setRefreshId(false)
            queryClient.invalidateQueries({ queryKey: ['Card-Data'] });
        },
        onError: (error) => {
            console.log("Error During Card Update: ", error);
            toast.error(`Failed to add Card: ${error}`)
        }
    });

    const findDataMeterColumn = (keys) => {
        return CategoryPerameter?.filter((item) => (item?.table_type == "instantaneous" && item?.unique_id == keys))[0]?.name
    }

    if (isTabsLoading || isTabPermissionUserDataLoading) return <div className='main-content tw-w-full tw-flex tw-justify-center tw-items-center tw-h-[80dvh]' ><GoogleLoader /></div>

    return (
        <>
            {/* // model for add tabs */}
            <IotModel
                isModalOpen={showTabModal}
                onOpenChange={HandleTabCloseFilter}
                modalTitle={isTabEdit ? "Update New Tab" : "Add New Tab"}
                AddEditModelClassName={"lg:tw-min-w-[35%] lg:tw-max-w-[35%] md:tw-w-[90%]"}
            >
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className="tw-flex tw-flex-col tw-gap-2">
                        <FormField
                            control={form.control}
                            name="tab_name"
                            rules={{ required: "Tab Name is required" }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name : <span className='tw-text-red-500 tw-text-sm' >*</span></FormLabel>
                                    <FormControl>
                                        <div className="relative">
                                            <InputBlock
                                                leftIcon={<Layout />}
                                                placeholder="Tab Name"
                                                {...field}
                                                className="pl-10"
                                                variant='none'
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="tab_description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Description :</FormLabel>
                                    <FormControl>
                                        <div className="relative">
                                            <Textarea
                                                {...field}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <MultiSelector
                            name="view_permission"
                            label="View Permission"
                            options={UsersOptions}
                            control={form.control}
                        />
                        {
                            (isTabEdit && (permision?.roles_other_permission?.GSM_DASH_TAB?.active_inactive == true || userInfo?.role === 'ROLE1650614500708')) &&

                            <FormField
                                control={form.control}
                                name="status"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Status</FormLabel>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a view" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {
                                                    statusOption?.map((v) => (
                                                        <SelectItem value={v?.value}>{v?.label}</SelectItem>
                                                    ))
                                                }

                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />
                        }
                        <div className="tw-flex tw-justify-start tw-mt-4 tw-space-x-2">
                            <Button disabled={createTabMutation.isPending || updateTabMutation.isPending} type="submit" className='tw-bg-red-500 hover:!tw-bg-red-600' >
                                {
                                    createTabMutation.isPending || updateTabMutation.isPending ? <LoaderCircle className="tw-size-5 tw-animate-spin" /> : "Submit"
                                }

                            </Button>
                            <Button variant="outline" onClick={HandleTabCloseFilter}>
                                Cancel
                            </Button>

                        </div>
                    </form>
                </Form>
            </IotModel>

            {/* // modal for card */}
            <IotModel
                isModalOpen={showAddCardModal}
                onOpenChange={HandleCardCloseFilter}
                modalTitle={isCardEdit ? "Edit Card" : "Add Card"}
                AddEditModelClassName="lg:tw-min-w-[40%] lg:tw-max-w-[40%] md:tw-w-[90%] "
            >
                <Form {...addCardForm}>
                    <form onSubmit={addCardForm.handleSubmit(handleAddCardSubmit)} className="tw-space-y-4 tw-flex tw-flex-col tw-gap-y-2">
                        <FormField
                            control={addCardForm.control}
                            name="card_name"
                            rules={{ required: "Card Name is required" }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Card Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Card Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={addCardForm.control}
                            name="card_description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Card Description</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Card Description" {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={addCardForm.control}
                            name="slave_category"
                            rules={{ required: "Salve Category is required" }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Slave Category</FormLabel>
                                    <Select
                                        onValueChange={(value) => {
                                            if (value) {
                                                field.onChange(value)
                                            }
                                        }}
                                        value={field.value}
                                    >

                                        <FormControl className={""}>
                                            <SelectTrigger variant="ringShadow">
                                                <SelectValue />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                {salveCategoryOptions.length === 0 ? <SelectLabel>No options available</SelectLabel> :
                                                    salveCategoryOptions.length > 10 ?
                                                        <ScrollArea className="!tw-h-72">
                                                            {salveCategoryOptions.map((option) => (
                                                                <SelectItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </SelectItem>
                                                            ))}
                                                        </ScrollArea> : (
                                                            salveCategoryOptions.map((option) => (
                                                                <SelectItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </SelectItem>
                                                            ))
                                                        )}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {
                            slave_category && <FormField
                                control={addCardForm.control}
                                name="slave_type_id"
                                rules={{ required: "Salve Type is required" }}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Type</FormLabel>
                                        <Select
                                            onValueChange={(value) => {
                                                if (value) {
                                                    field.onChange(value)
                                                }
                                            }}
                                            value={field.value}
                                        >
                                            {/* CRM-Task change css */}
                                            <FormControl className={""}>
                                                <SelectTrigger variant="ringShadow">
                                                    <SelectValue />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {salveTypeOptions.length === 0 ? <SelectLabel>No options available</SelectLabel> :
                                                        salveTypeOptions.length > 10 ?
                                                            <ScrollArea className="!tw-h-72">
                                                                {salveTypeOptions.map((option) => (
                                                                    <SelectItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </SelectItem>
                                                                ))}
                                                            </ScrollArea> : (
                                                                salveTypeOptions.map((option) => (
                                                                    <SelectItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </SelectItem>
                                                                ))
                                                            )}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />}
                        {/* <FormField
                            control={addCardForm.control}
                            name="multification_fector"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Multification Factor</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Card Description" {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        /> */}
                        {/* <FormField
                          control={addCardForm.control}
                          name="cardView"
                          render={({ field }) => (
                              <FormItem>
                                  <FormLabel>Card View</FormLabel>
                                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                                      <FormControl>
                                          <SelectTrigger>
                                              <SelectValue placeholder="Select a view" />
                                          </SelectTrigger>
                                      </FormControl>
                                      <SelectContent>
                                          <SelectItem value="List View">List View</SelectItem>
                                          <SelectItem value="Grid View">Grid View</SelectItem>
                                      </SelectContent>
                                  </Select>
                              </FormItem>
                          )}
                      />
                      <FormField
                          control={addCardForm.control}
                          name="cardColor"
                          render={({ field }) => (
                              <FormItem>
                                  <FormLabel>Card Color</FormLabel>
                                  <FormControl className='tw-relative' >
                                      <ColorPicker {...field} />
                                  </FormControl>
                                  <FormMessage />
                              </FormItem>
                          )}
                      /> */}

                        {slave_type_id && (<>
                            <MultiSelector
                                name="group_id"
                                label="Slaves Group"
                                options={SlaveOptions}
                                control={addCardForm.control}

                            />
                            <div className='tw-flex tw-justify-between tw-items-start' >
                                <MultiSelector
                                    className='tw-flex-1 tw-border-r tw-border-r-gray-600/50 tw-pe-2'
                                    name="addition_slave_list"
                                    label="Addition Salve"
                                    options={SlaveDataByGroupIdOptions}
                                    control={addCardForm.control}
                                />

                                <MultiSelector
                                    className='tw-flex-1 tw-ps-2'
                                    name="substraction_slave_list"
                                    label="Substraction Salve"
                                    options={SlaveDataByGroupIdOptions}
                                    control={addCardForm.control}
                                />
                            </div>

                        </>)}
                        <MultiSelector
                            name="view_permission"
                            label="View Permission"
                            options={UsersOptions}
                            control={addCardForm.control}
                        />

                        {
                            (isCardEdit && (permision?.roles_other_permission?.GSM_DASH_CARD?.active_inactive == true || userInfo?.role === 'ROLE1650614500708')) &&

                            <FormField
                                control={addCardForm.control}
                                name="status"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Status</FormLabel>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a view" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {
                                                    statusOption?.map((v) => (
                                                        <SelectItem value={v?.value}>{v?.label}</SelectItem>
                                                    ))
                                                }

                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />
                        }


                        <div className="tw-flex tw-justify-start tw-mt-4 tw-space-x-2">
                            <Button disabled={createCardMutation.isPending || updateCardMutation.isPending} type="submit" className='tw-bg-red-500 hover:!tw-bg-red-600' >
                                {
                                    createCardMutation.isPending || updateCardMutation.isPending ? <LoaderCircle className="tw-size-5 tw-animate-spin" /> : "Submit"
                                }

                            </Button>
                            <Button variant="outline" onClick={HandleCardCloseFilter}>
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Form>
            </IotModel>
            <Modal className='' show={isTableInfoModalOpen}  >
                <Modal.Header>
                    <Modal.Title>{totalData?.card_name}</Modal.Title>
                    <button type="button" class="close" onClick={handleTableCardInfoModal}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className='mb-4' value={activeTab} onValueChange={setActiveTab} >
                        <TabsList className='tw-gap-x-2 tw-bg-transparent ' >
                            <TabsTrigger backDrop="indigoLight" variant="indigo" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="available">Available Data List</TabsTrigger>
                            <TabsTrigger backDrop="dangerLight" variant="danger" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="unavailable">Unavailable Data List</TabsTrigger>
                            <TabsTrigger backDrop="cyanLight" variant="cyan" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="all">All Slave List</TabsTrigger>
                        </TabsList>
                        <div className="space-y-8 mt-3">
                            {activeTab === "available" &&
                                <div className='tw-border-[1px] tw-border-solid tw-border-gray-300 tw-rounded tw-overflow-hidden tw-shadow ' >
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2  ' >Addition Of Salves :-</h4>
                                    {renderTable(FoundData, true, SlaveDataByGroupIdOptions, totalData?.slave_type_id === "SLTYID1727529848745", CategoryPerameter)}
                                    {NotFoundData.length ? <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2' >Substraction Of Salves :-</h4> : ''}
                                    {NotFoundData.length ? renderTable(NotFoundData, true, SlaveDataByGroupIdOptions, totalData?.slave_type_id === "SLTYID1727529848745", CategoryPerameter) : ''}
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2' >Total :-</h4>
                                    <Table parentClassname="tw-rounded tw-shadow tw-max-h-[45dvh] tw-overflow-auto  ">
                                        <TableHeader className='tw-sticky tw-top-0 tw-z-[99999] tw-border-b-gray-300' >
                                            <TableRow className="tw-bg-gray-100">
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[140px]">

                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[100px]" >

                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[150px]" >

                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {
                                                        totalData?.slave_type_id === "SLTYID1727529848745" ? (findDataMeterColumn('C11705496910094')) : (findDataMeterColumn('C11713179311235'))
                                                    }
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                </TableHead>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11705498131318')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                        </TableHead>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11705579863448')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                        </TableHead>
                                                    </>
                                                }
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {
                                                        totalData?.slave_type_id === "SLTYID1727529848745" ? findDataMeterColumn('C11706099152958') : findDataMeterColumn('C11713179361869')
                                                    }
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                </TableHead>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11706099159675')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                        </TableHead>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11706099165030')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                        </TableHead>
                                                    </>
                                                }
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {findDataMeterColumn('C11706099539725')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {totalData?.slave_type_id === "SLTYID1727529848745" ? findDataMeterColumn('C11706099695482') : findDataMeterColumn('C11713179418265')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {findDataMeterColumn('C11706099559645')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                </TableHead>
                                                {/* <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                    {findDataMeterColumn('C11708343105812')}
                    </TableHead> */}
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {findDataMeterColumn('C11706099734927')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                </TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {/* // for total col */}

                                            <TableRow className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-slate-300/90 hover:tw-bg-slate-300  ' >
                                                <TableCell colSpan={3} className="!tw-font-bold !tw-text-base tw-text-center tw-p-3 ">
                                                    Total
                                                </TableCell>

                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-p-3">
                                                    {totalData?.voltage_rn != null && Number(totalData?.voltage_rn)?.toFixed(2) || '-'}
                                                </TableCell>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableCell className=" !tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.voltage_yn != null ? Number(totalData?.voltage_yn)?.toFixed(2) : '-'}
                                                        </TableCell>
                                                        <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.voltage_bn != null && Number(totalData?.voltage_bn)?.toFixed(2) || '-'}
                                                        </TableCell>
                                                    </>
                                                }
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.current_ir != null && Number(totalData?.current_ir)?.toFixed(2) || '-'}
                                                </TableCell>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.current_iy != null && Number(totalData?.current_iy)?.toFixed(2) || '-'}
                                                        </TableCell>
                                                        <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.current_ib != null && Number(totalData?.current_ib)?.toFixed(2) || '-'}
                                                        </TableCell>
                                                    </>
                                                }
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.active_power != null && Number(totalData?.active_power)?.toFixed(2) || '-'}
                                                </TableCell>
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.pf != null && Number(totalData?.pf)?.toFixed(2) || '-'}
                                                </TableCell>
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.apparent_power != null && Number(totalData?.apparent_power)?.toFixed(2) || '-'}
                                                </TableCell>
                                                {/* <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.total_energy!=null&&Number(totalData?.total_energy)?.toFixed(2)||'-'}
                                                </TableCell> */}
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.frequency != null && Number(totalData?.frequency)?.toFixed(2) || '-'}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </div>}
                            {/* {console.log('UnavailableData',UnavailableData)} */}

                            {activeTab === "unavailable" && (UnavailableData.length > 0 ? <div>
                                <Table parentClassname="tw-rounded tw-shadow tw-max-h-[45dvh] tw-overflow-auto  ">
                                    <TableHeader className='tw-sticky tw-top-0 tw-z-[99999] tw-border-b-gray-300' >
                                        <TableRow className="tw-bg-gray-100">

                                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[150px]" >
                                                SRNO
                                            </TableHead>
                                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                Slave address
                                            </TableHead>
                                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                Slave Name
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>

                                        {UnavailableData.map((item, id) => (
                                            <TableRow key={id} className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-white  ' >
                                                <TableCell className="tw-font-semibold tw-p-3">
                                                    {id + 1}
                                                </TableCell>
                                                <TableCell className="tw-font-semibold tw-p-3">
                                                    {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_address}

                                                </TableCell>
                                                <TableCell className="tw-font-semibold tw-p-3">
                                                    {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_name}

                                                </TableCell>

                                            </TableRow>
                                        ))}


                                    </TableBody>
                                </Table>
                            </div> : <h4 className='tw-flex tw-justify-center tw-h-48 tw-items-center' >No Data Available</h4>)}
                            {activeTab === "all" && (
                                <div className='tw-border-[1px] tw-border-solid tw-border-gray-300 tw-rounded tw-overflow-hidden tw-shadow ' >
                                    <Table parentClassname="tw-rounded tw-shadow tw-max-h-[45dvh] tw-overflow-auto  ">
                                        <TableHeader className='tw-sticky tw-top-0 tw-z-[99999] tw-border-b-gray-300' >
                                            <TableRow className="tw-bg-gray-100">
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[150px]" >
                                                    SRNO
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    Slave address
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    Slave Name
                                                </TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {[...JSON.parse(totalData?.addition_slave_list || '[]'), ...JSON.parse(totalData?.substraction_slave_list || '[]')].map((item, id) => (
                                                <TableRow key={id} className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-white  ' >
                                                    <TableCell className="tw-font-semibold tw-p-3">
                                                        {id + 1}
                                                    </TableCell>
                                                    <TableCell className="tw-font-semibold tw-p-3">
                                                        {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_address}

                                                    </TableCell>
                                                    <TableCell className="tw-font-semibold tw-p-3">
                                                        {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_name}

                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                        </div>
                    </Tabs>
                </Modal.Body>
            </Modal>
            <Modal className="consumption-chart-modal" show={isTableInfoModalOpen}  >
                <Modal.Header>
                    <Modal.Title>{totalData?.card_name}</Modal.Title>
                    <button type="button" class="close" onClick={handleTableCardInfoModal}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className='mb-4' value={activeTab} onValueChange={setActiveTab} >
                        <TabsList className='tw-gap-x-2 tw-bg-transparent ' >
                            <TabsTrigger backDrop="indigoLight" variant="indigo" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="available">Available Data List</TabsTrigger>
                            <TabsTrigger backDrop="dangerLight" variant="danger" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="unavailable">Unavailable Data List</TabsTrigger>
                            <TabsTrigger backDrop="cyanLight" variant="cyan" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="all">All Slave List</TabsTrigger>
                        </TabsList>
                        <div className="space-y-8 mt-3">
                            {activeTab === "available" &&
                                <div className='tw-border-[1px] tw-border-solid tw-border-gray-300 tw-rounded tw-overflow-hidden tw-shadow ' >
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2  ' >Addition Of Salves :-</h4>
                                    {renderTable(FoundData, true, SlaveDataByGroupIdOptions, totalData?.slave_type_id === "SLTYID1727529848745", CategoryPerameter)}

                                    {NotFoundData.length ? <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2' >Substraction Of Salves :-</h4> : ''}
                                  
                                    {NotFoundData.length ? renderTable(NotFoundData, true, SlaveDataByGroupIdOptions, totalData?.slave_type_id === "SLTYID1727529848745", CategoryPerameter) : ''}
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2' >Total :-</h4>
                                    <Table parentClassname="tw-rounded tw-shadow tw-max-h-[45dvh] tw-overflow-auto  ">
                                        <TableHeader className='tw-sticky tw-top-0 tw-z-[99999] tw-border-b-gray-300' >
                                            <TableRow className="tw-bg-gray-100">
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[140px]">

                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[100px]" >

                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[150px]" >

                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {
                                                        totalData?.slave_type_id === "SLTYID1727529848745" ? (findDataMeterColumn('C11705496910094')) : (findDataMeterColumn('C11713179311235'))
                                                    }
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                </TableHead>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11705498131318')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                        </TableHead>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11705579863448')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                        </TableHead>
                                                    </>
                                                }
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {
                                                        totalData?.slave_type_id === "SLTYID1727529848745" ? findDataMeterColumn('C11706099152958') : findDataMeterColumn('C11713179361869')
                                                    }
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                </TableHead>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11706099159675')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                        </TableHead>
                                                        <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                            {findDataMeterColumn('C11706099165030')}
                                                            <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                        </TableHead>
                                                    </>
                                                }
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {findDataMeterColumn('C11706099539725')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {totalData?.slave_type_id === "SLTYID1727529848745" ? findDataMeterColumn('C11706099695482') : findDataMeterColumn('C11713179418265')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {findDataMeterColumn('C11706099559645')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                                                </TableHead>
                                                {/* <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                    {findDataMeterColumn('C11708343105812')}
                    </TableHead> */}
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                                    {findDataMeterColumn('C11706099734927')}
                                                    <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                                                </TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {/* // for total col */}

                                            <TableRow className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-slate-300/90 hover:tw-bg-slate-300  ' >
                                                <TableCell colSpan={3} className="!tw-font-bold !tw-text-base tw-text-center tw-p-3 ">
                                                    Total
                                                </TableCell>

                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-p-3">
                                                    {totalData?.voltage_rn != null && Number(totalData?.voltage_rn)?.toFixed(2) || '-'}
                                                </TableCell>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableCell className=" !tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.voltage_yn != null ? Number(totalData?.voltage_yn)?.toFixed(2) : '-'}
                                                        </TableCell>
                                                        <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.voltage_bn != null && Number(totalData?.voltage_bn)?.toFixed(2) || '-'}
                                                        </TableCell>
                                                    </>
                                                }
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.current_ir != null && Number(totalData?.current_ir)?.toFixed(2) || '-'}
                                                </TableCell>
                                                {
                                                    totalData?.slave_type_id === "SLTYID1727529848745" && <>
                                                        <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.current_iy != null && Number(totalData?.current_iy)?.toFixed(2) || '-'}
                                                        </TableCell>
                                                        <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                            {totalData?.current_ib != null && Number(totalData?.current_ib)?.toFixed(2) || '-'}
                                                        </TableCell>
                                                    </>
                                                }
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.active_power != null && Number(totalData?.active_power)?.toFixed(2) || '-'}
                                                </TableCell>
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.pf != null && Number(totalData?.pf)?.toFixed(2) || '-'}
                                                </TableCell>
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.apparent_power != null && Number(totalData?.apparent_power)?.toFixed(2) || '-'}
                                                </TableCell>
                                                {/* <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.total_energy!=null&&Number(totalData?.total_energy)?.toFixed(2)||'-'}
                                                </TableCell> */}
                                                <TableCell className="!tw-text-base tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    {totalData?.frequency != null && Number(totalData?.frequency)?.toFixed(2) || '-'}
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </div>}
                            {/* {console.log('UnavailableData',UnavailableData)} */}

                            {activeTab === "unavailable" && (UnavailableData.length > 0 ? <div>
                                <Table parentClassname="tw-rounded tw-shadow tw-max-h-[45dvh] tw-overflow-auto  ">
                                    <TableHeader className='tw-sticky tw-top-0 tw-z-[99999] tw-border-b-gray-300' >
                                        <TableRow className="tw-bg-gray-100">

                                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[150px]" >
                                                SRNO
                                            </TableHead>
                                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                Slave address
                                            </TableHead>
                                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                Slave Name
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>

                                        {UnavailableData.map((item, id) => (
                                            <TableRow key={id} className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-white  ' >
                                                <TableCell className="tw-font-semibold tw-p-3">
                                                    {id + 1}
                                                </TableCell>
                                                <TableCell className="tw-font-semibold tw-p-3">
                                                    {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_address}

                                                </TableCell>
                                                <TableCell className="tw-font-semibold tw-p-3">
                                                    {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_name}

                                                </TableCell>

                                            </TableRow>
                                        ))}


                                    </TableBody>
                                </Table>
                            </div> : <h4 className='tw-flex tw-justify-center tw-h-48 tw-items-center' >No Data Available</h4>)}
                            {activeTab === "all" && (
                                <div className='tw-border-[1px] tw-border-solid tw-border-gray-300 tw-rounded tw-overflow-hidden tw-shadow ' >
                                    <Table parentClassname="tw-rounded tw-shadow tw-max-h-[45dvh] tw-overflow-auto  ">
                                        <TableHeader className='tw-sticky tw-top-0 tw-z-[99999] tw-border-b-gray-300' >
                                            <TableRow className="tw-bg-gray-100">
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase tw-w-[150px]" >
                                                    SRNO
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    Slave address
                                                </TableHead>
                                                <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                                    Slave Name
                                                </TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {[...JSON.parse(totalData?.addition_slave_list || '[]'), ...JSON.parse(totalData?.substraction_slave_list || '[]')].map((item, id) => (
                                                <TableRow key={id} className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-white  ' >
                                                    <TableCell className="tw-font-semibold tw-p-3">
                                                        {id + 1}
                                                    </TableCell>
                                                    <TableCell className="tw-font-semibold tw-p-3">
                                                        {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_address}

                                                    </TableCell>
                                                    <TableCell className="tw-font-semibold tw-p-3">
                                                        {SlaveDataByGroupIdOptions.filter((info) => item == info.value)[0]?.slave_name}

                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                        </div>
                    </Tabs>
                </Modal.Body>
            </Modal>

            <SlaveConsumptions
                isOpen={consumptionModel?.isOpen}
                modalHeader={consumptionModel?.headerName} 
                onCloseHandler={() => setconsumptionModel((prv) => ({ ...prv, isOpen: false, cardId: null, headerName: null }))}
                tabId={currentTabs}
                cardId={consumptionModel?.cardId}
                viewCradPerMission={cards_id}
            />
            <div className='main-content' >
                <div className='tw-flex  tw-flex-row tw-justify-between tw-items-start md:tw-gap-x-4' >

                    <div className={`lg:tw-w-[250px] tw-bg-[#fff] md:tw-w-[180px]  tw-shadow-custom tw-rounded-lg tw-max-h-[88dvh] tw-h-[88dvh] md:tw-overflow-visible ${isTabshow ? "tw-w-full" : "tw-w-0 tw-overflow-hidden"} `} >
                        <div className='tw-h-full tw-w-full tw-flex tw-flex-col tw-flex-1' >
                            <Tabs className='tw-h-full tw-flex tw-items-start tw-flex-grow tw-overflow-y-auto' defaultValue={currentTabs} value={currentTabs} >
                                <TabsList className='tw-flex-col tw-flex tw-h-auto tw-bg-[#fff] tw-w-full' >
                                    {
                                        tabsOptions.map(({ value, label }) => (
                                            // {/* // T-TD10216 add tw-w-full tw-justify-start tw-items-center*/}
                                            <React.Fragment key={value} >

                                                <BootstrapTooltip title={label} >

                                                    <TabsTrigger
                                                        variant="blue"
                                                        className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-center '
                                                        value={value}
                                                        onClick={() => handleTabChange(value)}
                                                    >

                                                        <>
                                                            <p
                                                                className='tw-flex-1 tw-mb-0 tw-text-start tw-overflow-hidden tw-text-ellipsis tw-w-full  lg:tw-max-w-[150px] tw-truncate lg:tw-hover:tw-max-w-none   lg:tw-hover:tw-shadow-md lg:tw-hover:tw-z-10 tw-duration-300'
                                                                title={label} // For non-hovered screens to show full text in a tooltip
                                                            >
                                                                {label}
                                                            </p>

                                                            {/* // T-TD10216 use DropdownMenu*/}
                                                            <DropdownMenu className='tw-w-[3%]' >
                                                                <DropdownMenuTrigger asChild >
                                                                    <Button
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()// Set state when dropdown is clicked
                                                                        }}
                                                                        size='xs' type='button' variant="ghost" className="tw-rounded-full tw-text-indigo-500 hover:tw-bg-indigo-100 hover:tw-text-indigo-600" ><EllipsisVertical size={14} /></Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent className="tw-px-0 tw-py-2 tw-w-[50px] -tw-translate-x-2 tw-bg-white" >
                                                                    <DropdownMenuCheckboxItem onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        HandleTabEdit(value)
                                                                    }} className='tw-cursor-pointer tw-flex tw-gap-x-1 tw-items-center tw-text-[13px] tw-leading-3  !tw-px-5 hover:tw-text-indigo-500 hover:tw-bg-indigo-50 tw-duration-200' >
                                                                        <Edit size={16} /> Edit
                                                                    </DropdownMenuCheckboxItem>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        </>


                                                    </TabsTrigger>
                                                </BootstrapTooltip>
                                                {/* // T-TD10216 install react-Tooltip */}
                                            </React.Fragment>
                                        ))
                                    }
                                </TabsList>
                            </Tabs>
                            <div className='tw-mt-2 tw-w-full tw-items-center tw-p-1 tw-flex tw-gap-2' >
                                <div className='tw-w-auto' >

                                    <DropdownMenu className='' >
                                        <DropdownMenuTrigger asChild >
                                            <Button variant='indigo' size='icon'><Filter size={16} /></Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="tw-px-0 tw-py-2 tw-w-[50px] -tw-translate-x-2 tw-bg-white" >

                                            {
                                                statusFilterOptions?.map((v) => (
                                                    <DropdownMenuCheckboxItem key={v?.value} onClick={() => setTabStatus(v?.value)} className={`${Tabstatus === v?.value && 'tw-bg-slate-100'} tw-py-3 tw-cursor-pointer tw-flex tw-gap-x-1 tw-items-center tw-text-[13px] tw-leading-3  !tw-px-5 hover:tw-text-indigo-500 hover:tw-bg-indigo-50 tw-duration-200`} >
                                                        {v?.label}
                                                    </DropdownMenuCheckboxItem>
                                                ))
                                            }


                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>

                                <Button variant='dotten' border='indigo' className='tw-w-full  tw-border-[2px] tw-whitespace-nowrap' onClick={() => {
                                    if (permision?.roles_other_permission?.GSM_DASH_TAB?.add == true || userInfo?.role === 'ROLE1650614500708') {
                                        setShowTabModal(true)
                                        form.reset({
                                            tab_name: '',
                                            tab_description: '',
                                            view_permission: UsersOptions.filter((userInfos) => (userInfo.unique_id === userInfos?.value)).map(item => item?.value)
                                        })
                                    } else {
                                        return toast.error(`You Don't have permission Add Tab`)
                                    }
                                }} >+ Add New Tab</Button>
                            </div>
                        </div>

                    </div>

                    <div className={` md:tw-w-full tw-max-h-[88dvh] tw-h-[88dvh] md:tw-overflow-visible ${isTabshow ? "tw-w-0 tw-overflow-hidden" : "tw-w-full"}`}>
                        <div className='tw-h-full tw-w-full tw-flex tw-flex-col tw-flex-1 ' >
                            <div className='tw-shadow-custom tw-p-4 tw-rounded-md tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-2 tw-z-20 tw-bg-white tw-gap-y-3' >

                                <Button className='tw-visible md:tw-hidden' variant='info' size='icon' onClick={() => setisTabshow(true)} ><ChevronLeft size={14} /> </Button>

                                <h2 className='tw-text-lg tw-font-bold tw-mb-0' >
                                    {tabsOptions?.find((v) => v?.value === currentTabs)?.label}
                                </h2>



                                <div className='tw-flex tw-flex-wrap tw-justify-between tw-items-center md:tw-w-auto tw-w-full tw-gap-2' >
                                    <div className='tw-w-[100px]' >
                                        <Select onValueChange={(e) => {
                                            setStatus(e)
                                        }} value={status}  >
                                            <SelectTrigger >
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {
                                                    statusFilterOptions?.map((v) => (
                                                        <SelectItem key={v?.value} value={v?.value}>{v?.label}</SelectItem>
                                                    ))
                                                }

                                            </SelectContent>
                                        </Select>
                                    </div>

                                    {/* <DropdownMenu className='' >
                                        <DropdownMenuTrigger asChild >
                                            <Button variant='info' size='icon' ><Settings size={14} /></Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="tw-px-0 tw-py-2 tw-w-[50px] -tw-translate-x-2 tw-bg-white" >
                                            <DropdownMenuCheckboxItem onClick={() => HandleTabEdit()} className='tw-cursor-pointer tw-flex tw-gap-x-1 tw-items-center tw-text-[13px] tw-leading-3  !tw-px-5 hover:tw-text-indigo-500 hover:tw-bg-indigo-50 tw-duration-200' >
                                                <Edit size={16} /> Edit
                                            </DropdownMenuCheckboxItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu> */}
                                    <Button variant='info' size='icon' onClick={() => {
                                        if (permision?.roles_other_permission?.GSM_DASH_CARD?.add == true || userInfo?.role === 'ROLE1650614500708') {
                                            setShowAddCardModal(true)
                                            addCardForm.reset({
                                                card_name: '',
                                                card_description: '',
                                                multification_fector: '',
                                                cardView: 'List View',
                                                cardColor: '#ffffff',
                                                group_id: [],
                                                slaveType: 'Addition',
                                                addition_slave_list: [],
                                                substraction_slave_list: [],
                                                view_permission: UsersOptions.filter((userInfos) => (userInfo.unique_id === userInfos?.value)).map(item => item?.value)
                                            })
                                        } else {
                                            return toast.error(`You Don't have permission Add Card`)
                                        }
                                    }} ><Plus size={14} /> </Button>
                                </div>
                            </div>

                            {
                                isCardPermissionUserDataLoading || isCardDataLoading || isTabsLoading ? <div className='tw-w-full tw-flex tw-justify-center tw-items-center  tw-h-[70dvh]' ><GoogleLoader /></div> : cardDataArray.length === 0 ?
                                    <div className="  tw-mt-4 tw-rounded-xl">
                                        <div className="tw-text-center tw-flex tw-flex-grow tw-h-[78dvh] tw-bg-white tw-items-center tw-justify-center tw-z-10 tw-text-gray-500 tw-shadow-custom">
                                            <div>
                                                <AlertCircle size={40} className="tw-mx-auto tw-mb-2" />
                                                <p className="tw-mb-0 fs-4 tw-text-gray-600  tw-capitalize !tw-font-bold">Data Not Found</p>
                                            </div>

                                        </div>
                                    </div> :
                                    <div className='tw-flex-grow tw-overflow-y-auto tw-pr-2' >


                                        {
                                            isCardDataLoading ? <div className='tw-w-full  tw-h-[50dvh]' ><DotLoader /></div>
                                                : <div className='tw-my-4 tw-mb-6 tw-grid xl:tw-grid-cols-3 md:tw-grid-cols-2 tw-grid-cols-1 lg:tw-gap-6 tw-gap-3' >
                                                    {
                                                        cardDataArray?.map((v, i) => (
                                                            <IOTCard data={v} onhandleTabChange={(e) => HandleEditCard(e)} onRefreshBtn={(id) => onRefreshBtn(id)} RefreshId={RefreshId} RefreshIndi={RefreshIndi} onHandleTableModal={handleModalOpen} onHandleConsumptionModel={(value) => setconsumptionModel((prv) => ({ ...prv, isOpen: true, cardId: value?.unique_id, headerName: value?.name }))}/>
                                                        ))
                                                    }
                                                </div>
                                        }
                                    </div>
                            }


                        </div>



                    </div>
                </div>
            </div>

        </>

    )
}


const ColorPicker = React.forwardRef(({ value, onChange, ...props }, ref) => {
    const [showPicker, setShowPicker] = useState(false);

    return (
        <div className="tw-relative tw-flex tw-justify-between tw-gap-2">
            <Input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                ref={ref}
                {...props}
            />
            <input
                type="color"
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                className="tw-size-[35px]"
            />
        </div>
    );
});

const MultiSelector = ({ name, label, options, control, onValueChange,className, controlClassName, labelClassName }) => (
    <FormField
        control={control}
        name={name}
        render={({ field }) => (
            <FormItem className={`${className ? className : ""}`}>
                <FormLabel className={`tw-font-medium ${labelClassName ?? ""}`}>{label}:</FormLabel>
                <FormControl  className={`${controlClassName ?? ""}`}>
                    <ReactMultiSelector
                        {...field}
                        options={options}
                        isMulti
                        className=""
                        classNamePrefix="react-select"
                        value={
                            field.value
                                ? options.filter((option) =>
                                    field.value.includes(option?.value)
                                )
                                : []
                        }
                        onChange={(value) => {
                            field.onChange(
                                value ? value.map((item) => item.value) : []
                            )
                            // console.log('onValueChange', onValueChange)
                            if (onValueChange) {
                                onValueChange()
                            }
                        }
                        }
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#6366F1' : '#A5B4FC',
                                '&:hover': {
                                    borderColor: '#6366F1',
                                }
                            }),
                        }}
                    />
                </FormControl>
                <FormMessage />
            </FormItem>
        )}
    />
);


const renderTable = (data, showTotal = true, SlaveDataByGroupIdOptions, phaseType, CategoryPerameterData) => {
    // console.log('CategoryPerameterData ===>',CategoryPerameterData )
    const findDataMeterColumn = (keys) => {
        // console.log(keys,CategoryPerameterData?.filter((item)=>(item?.table_type=="instantaneous"&&item?.unique_id==keys)))
        return CategoryPerameterData?.filter((item) => (item?.table_type == "instantaneous" && item?.unique_id == keys))[0]?.name
    }
    return (<div className=' tw-rounded tw-shadow '  >
        <Table parentClassname="tw-w-full  tw-border-b-gray-300 tw-max-h-[45dvh] tw-overflow-auto ">
            <TableHeader className='tw-sticky tw-top-0 tw-z-[1000] tw-border-b-gray-300'  >
                <TableRow className="tw-bg-gray-100">
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                        srno
                    </TableHead>
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                        unique_id
                    </TableHead>
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                        slave name
                    </TableHead>
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">

                        {
                            phaseType ? (findDataMeterColumn('C11705496910094')) : (findDataMeterColumn('C11713179311235'))
                        }
                        <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                    </TableHead>
                    {
                        phaseType && <>
                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                {findDataMeterColumn('C11705498131318')}
                                <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                            </TableHead>
                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                {findDataMeterColumn('C11705579863448')}
                                <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                            </TableHead>
                        </>
                    }
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                        {
                            phaseType ? findDataMeterColumn('C11706099152958') : findDataMeterColumn('C11713179361869')
                        }
                        <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                    </TableHead>
                    {
                        phaseType && <>
                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                {findDataMeterColumn('C11706099159675')}
                                <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                            </TableHead>
                            <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                                {findDataMeterColumn('C11706099165030')}
                                <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                            </TableHead>
                        </>
                    }
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                        {findDataMeterColumn('C11706099539725')}
                        <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                    </TableHead>
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                        {phaseType ? findDataMeterColumn('C11706099695482') : findDataMeterColumn('C11713179418265')}
                        <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                    </TableHead>
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                        {findDataMeterColumn('C11706099559645')}
                        <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Sum)</span>
                    </TableHead>
                    {/* <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                    {findDataMeterColumn('C11708343105812')}
                    </TableHead> */}
                    <TableHead className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 ">
                        {findDataMeterColumn('C11706099734927')}
                        <span className='tw-block tw-text-[10px] tw-text-gray-500' >(Avg)</span>
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data.map((row, index) => (
                    <TableRow key={index} className='tw-border-b-gray-300' >
                        <TableCell className="tw-text-sm tw-w-[100px] tw-p-3">{index + 1}</TableCell>
                        {/* <TableCell className="tw-text-sm tw-w-[100px] tw-p-3">{row?.imei}</TableCell> */}
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.unique_id}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {SlaveDataByGroupIdOptions.filter((item) => row?.unique_id == item.value)[0]?.slave_name}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.voltage_rn != null && row?.voltage_rn?.toFixed(2) || '-'}
                        </TableCell>
                        {phaseType && <>
                            <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                {row?.voltage_yn != null && row?.voltage_yn?.toFixed(2) || '-'}
                            </TableCell>
                            <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                {row?.voltage_bn != null && row?.voltage_bn?.toFixed(2) || '-'}
                            </TableCell>
                        </>}
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.current_ir != null && row?.current_ir?.toFixed(2) || '-'}
                        </TableCell>
                        {phaseType && <>
                            <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                {row?.current_iy != null && row?.current_iy?.toFixed(2) || '-'}
                            </TableCell>
                            <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                                {row?.current_ib != null && row?.current_ib?.toFixed(2) || '-'}
                            </TableCell>
                        </>}
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.active_power != null && row?.active_power?.toFixed(2) || '-'}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.pf != null && row?.pf?.toFixed(2) || '-'}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.apparent_power != null && row?.apparent_power?.toFixed(2) || '-'}
                        </TableCell>
                        {/* <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.total_energy!=null&&row?.total_energy?.toFixed(2)||'-'}
                        </TableCell> */}
                        <TableCell className="tw-text-xs tw-text-center !tw-font-bold tw-text-gray-700 tw-uppercase">
                            {row?.frequency != null && row?.frequency?.toFixed(2) || '-'}
                        </TableCell>

                    </TableRow>
                ))}

                {/* // for total col */}
                {showTotal && (
                    <TableRow className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-slate-200/80 hover:tw-bg-slate-200/90  ' >
                        <TableCell colSpan={3} className="tw-font-bold tw-text-center tw-p-3 ">
                            Total
                        </TableCell>

                        <TableCell className="tw-font-bold tw-text-center tw-p-3">
                            {data.filter((info) => (info?.voltage_rn)).length ? (data.reduce((acc, obj) => (acc += obj.voltage_rn), 0) / (data.filter((info) => (info?.voltage_rn)).length)).toFixed(2) : '-'}
                        </TableCell>
                        {phaseType && <>
                            <TableCell className="tw-font-bold tw-text-center tw-p-3">
                                {data.filter((info) => (info?.voltage_yn)).length ? (data.reduce((acc, obj) => (acc += obj.voltage_yn), 0) / (data.filter((info) => (info?.voltage_yn)).length)).toFixed(2) : '-'}
                            </TableCell>
                            <TableCell className="tw-font-bold tw-text-center tw-p-3">
                                {data.filter((info) => (info?.voltage_bn)).length ? (data.reduce((acc, obj) => (acc += obj.voltage_bn), 0) / (data.filter((info) => (info?.voltage_bn)).length)).toFixed(2) : '-'}
                            </TableCell>
                        </>}
                        <TableCell className="tw-font-bold tw-text-center tw-p-3">
                            {data.filter((info) => (info?.current_ir)).length ? data.reduce((acc, obj) => (acc += obj.current_ir), 0).toFixed(2) : '-'}
                        </TableCell>
                        {phaseType && <>
                            <TableCell className="tw-font-bold tw-text-center tw-p-3">
                                {data.filter((info) => (info?.current_iy)).length ? data.reduce((acc, obj) => (acc += obj.current_iy), 0).toFixed(2) : '-'}
                            </TableCell>
                            <TableCell className="tw-font-bold tw-text-center tw-p-3">
                                {data.filter((info) => (info?.current_ib)).length ? data.reduce((acc, obj) => (acc += obj.current_ib), 0).toFixed(2) : '-'}
                            </TableCell>
                        </>}
                        <TableCell className="tw-font-bold tw-text-center tw-p-3">
                            {data.filter((info) => (info?.active_power)).length ? data.reduce((acc, obj) => (acc += obj.active_power), 0).toFixed(2) : '-'}
                        </TableCell>
                        <TableCell className="tw-font-bold tw-text-center tw-p-3">
                            {data.filter((info) => (info?.pf)).length ? (data.reduce((acc, obj) => (acc += obj.pf), 0) / (data.filter((info) => (info?.pf)).length)).toFixed(2) : '-'}
                        </TableCell>
                        <TableCell className="tw-font-bold tw-text-center tw-p-3">
                            {data.filter((info) => (info?.apparent_power)).length ? data.reduce((acc, obj) => (acc += obj.apparent_power), 0).toFixed(2) : '-'}
                        </TableCell>
                        {/* <TableCell className="tw-font-bold tw-p-3">
                            {data.filter((info)=>(info?.total_energy)).length?data.reduce((acc, obj) => (acc += obj.total_energy), 0).toFixed(2):'-'}
                        </TableCell> */}
                        <TableCell className="tw-font-bold tw-text-center tw-p-3">
                            {data.filter((info) => (info?.frequency)).length ? (data.reduce((acc, obj) => (acc += obj.frequency), 0) / (data.filter((info) => (info?.frequency)).length)).toFixed(2) : '-'}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </div>)

}