import { useMutation } from '@tanstack/react-query'
import { getSlaveConsumptionsByCardId } from '../../../Common/Utils'
import React, { useMemo, useRef, useState } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../Common/ui/select';
import { Modal } from 'react-bootstrap';
import GoogleLoader from '../../../Common/Loaders/GoogleLoader';
import { toast } from 'react-toastify';
import { Button } from '../../../Common/ui/button';
import { X } from 'lucide-react';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { BootstrapTooltip } from '../../../Common/common';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    Decimation, TimeScale
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DatePicker } from 'antd';
import { Label } from '../../../Common/ui/label';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    zoomPlugin, ChartDataLabels,
    Decimation, TimeScale
);

export default function SlaveConsumptions({
    isOpen,
    onCloseHandler,
    modalHeader,
    tabId,
    cardId,
    viewCradPerMission,
}) {

    const [dates, setDates] = useState({ startDate: "", endDate: "" });
    const [interval, setInterval] = useState('daily');
    const [consumptionData, setConsumptionData] = useState({ lable: [], data: [] })
    const [hasZoomed, setHasZoomed] = useState(false);
    const [isDataFetch, setisDataFetch] = useState(false);
    var chartRefEvent = useRef(null)

    const intervalOption = [
        { value: "daily", label: "Daily" },
        { value: "hourly", label: "Hourly" },
    ]

    const OnModalClose = () => {
        setisDataFetch(false)
        setHasZoomed(false)
        setConsumptionData({ lable: [], data: [] })
        setDates({ startDate: '', endDate: '' });
        setInterval('daily')
        onCloseHandler()
    }

    const onGetConsumtionDataMutation = useMutation({
        mutationFn: () => getSlaveConsumptionsByCardId({ start_date: dates?.startDate, end_date: dates?.endDate, interval: interval, tabId, cardId, viewCradPerMission }),
        onSuccess: (res) => {
            let data = res?.data?.intervalSums;
            const values = Object.values(data || [])?.length > 0 ? Object.values(data || []).map((v) => v?.total) : []
            setConsumptionData((prv) => ({ ...prv, lable: Object.keys(data), data: values }));
        },
        onError: (error) => {
            console.log("Error During Get Data: ", error);
            toast.error(`Failed to Get consumption data: ${error}`)
        }
    });


    const onSubmit = () => {

        if (!dates?.startDate || !dates?.endDate) {
            toast.error("Both Start Date and End Date are required.");
            return;
        }

        // Check if start date is before end date
        if (new Date(dates?.startDate) >= new Date(dates?.endDate)) {
            toast.error("Start Date must be before End Date.");
            return;
        }

        setHasZoomed(false)
        setisDataFetch(true)

        onGetConsumtionDataMutation.mutate()
    }

    const getCommonChartOptions = ({ chartType, enableZoom = false, requireCtrl = false, isExpand = false }) => {
        const commonOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {

                    grid: { display: false },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 90,
                        minRotation: 90,
                        labelOffset: -10,
                        font: { size: 8 },
                        font: { size: 10 }
                    }
                },
                y: {
                    ticks: {
                        stepSize: 1
                    },
                    grace: '5%',
                    title: { 
                        display: true,
                        text: 'KWH',
                        font: {
                            size: 12,
                            lineHeight: 1.2
                        }
                    },
                    beginAtZero: true,
                    grid: { color: 'rgba(0, 0, 0, 0.1)' }
                }
            },
            plugins: {
                legend: { display: false, position: 'top' },
                tooltip: { enabled: true, mode: 'index', intersect: false },
                title: { display: false },
                datalabels: {
                    clamp: false,
                    clip: true,
                    color: "white",
                    padding: {
                        top: 0,
                        bottom: 0
                    }
                },
                zoom: enableZoom ? {
                    zoom: {
                        wheel: { enabled: true, modifierKey: requireCtrl ? 'ctrl' : undefined },
                        pinch: { enabled: true },
                        mode: 'x',
                        animation: { duration: 1000, easing: 'easeOutCubic' }
                    },
                    pan: {
                        enabled: true,
                        mode: 'x',
                        animation: { duration: 1000, easing: 'easeOutCubic' }
                    }
                } : undefined,

            }
        };

        if (chartType === 'bar') {
            commonOptions.scales.x.stacked = true;
            commonOptions.scales.y.stacked = true;
            commonOptions.plugins.datalabels = {
                clamp: false,
                clip: true,
                color: "white",
                padding: {
                    top: 20,
                    bottom: 0
                }
            };
        } else if (chartType === 'line') {
            commonOptions.elements = { line: { tension: 0.4 } };
            commonOptions.plugins.datalabels = { display: false };
        }

        // Adding isExpand-specific animation logic
        if (isExpand) {
            commonOptions.animation = {
                onComplete: function () {
                    if (chartRefEvent.current && !hasZoomed) {
                        handleZoom();
                        setHasZoomed(true);
                    }
                }
            };
        }

        return commonOptions;
    };

    const consumptionDatasets = useMemo(() => ({
        labels: consumptionData?.lable?.map(label => interval === 'hourly'
            ? [moment(label, 'DD-MM-YYYY HH:00').format("DD-MM-YYYY"),
            moment(label, 'DD-MM-YYYY HH:00').format("hh:mm:ss A (ddd)")
            ]
            :
            [moment(label, 'DD-MM-YYYY').format("DD-MM-YYYY")]

        ),
        datasets: [{
            label: 'Consumption ',
            data: consumptionData?.data,
            barBorderRadius: 5,
            backgroundColor: ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
        }],
    }), [consumptionData]);


    const barChartExpandOptionsWithCtrl = getCommonChartOptions({ chartType: 'bar', enableZoom: true, requireCtrl: false, isExpand: true });

    const zoomInEvent = ({ value = 1.2 }) => {
        if (chartRefEvent.current != undefined || chartRefEvent.current != null) {
            chartRefEvent.current?.zoom(value);
        }
    }

    const zoomOutEvent = () => {
        if (chartRefEvent.current != undefined || chartRefEvent.current != null) {
            chartRefEvent.current?.zoom(0.8);
        }
    }

    const panLeftEvent = () => {
        if (chartRefEvent.current != undefined || chartRefEvent.current != null) {
            chartRefEvent.current?.pan({ x: 400 }, undefined, 'default');
        }
    }

    const panRightEvent = () => {
        if (chartRefEvent.current != undefined || chartRefEvent.current != null) {
            chartRefEvent.current?.pan({ x: -400 }, undefined, 'default');
        }
    }

    const handleZoom = () => {
        setTimeout(() => {
            const xMax = chartRefEvent.current.scales.x.max;
            chartRefEvent.current.zoomScale('x', { min: Math.max(0, xMax - 25), max: xMax }, 'default')
        }, 150);
    }

    return (
        <Modal
            backdrop="static"
            keyboard={false}
            show={isOpen}
            onHide={OnModalClose}
            size="xl"

        >
             <Modal.Header className="border-bottom !tw-py-2">
                <Modal.Title className="!tw-font-bold !tw-text-2xl mb-0">
                     Consumption ({modalHeader})
                </Modal.Title>
                <button onClick={OnModalClose} type="button" className="btn-close tw-text-black" aria-label="Close"><X size={20} /></button>
            </Modal.Header>


            <Modal.Body className="tw-p-6">
                <div className="d-flex lg:tw-flex-row tw-flex-col lg:tw-items-end tw-justify-between tw-gap-y-2">
                    <div className="tw-flex tw-items-end tw-gap-2">
                        <div>
                            <Select
                                disabled={onGetConsumtionDataMutation?.isPending}
                                onValueChange={(e) => {
                                    if(e === 'hourly'){
                                        setDates(prev => ({ ...prev, startDate: !!dates?.startDate  ? moment(dates?.startDate,'DD-MM-YYYY').format('DD-MM-YYYY 12:00:00') : "" , endDate :  !!dates?.endDate ? moment(dates?.endDate,'DD-MM-YYYY').format('DD-MM-YYYY 12:00:00') : "" }))
                                    }else{
                                        setDates(prev => ({ ...prev, startDate:  !!dates?.startDate ? moment(dates?.startDate,'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') : "" , endDate : !!dates?.endDate ?  moment(dates?.endDate,'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') : "" }))
                                    }
                                    
                                    setInterval(e)
                                }}
                                value={interval}
                            >
                                <SelectTrigger>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    {intervalOption.map((v) => (
                                        <SelectItem key={v.value} value={v.value}>
                                            {v.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className='tw-min-w-[150px] tw-max-w-[200px] tw-flex tw-flex-col tw-gap-y-1' >
                            <Label className='mb-0' >End Date</Label>
                            <DatePicker
                                showTime={interval === 'daily' ? false : { format: 'HH' }}
                                onChange={(date, dateString) => { setDates(prev => ({ ...prev, startDate: dateString })) }}
                                format={interval === 'daily' ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:00:00'}
                                name='startDate'
                                placeholder='Start Date'
                                disabledDate={(current) => {
                                    return (moment(dates?.startDate).add(-1, 'days') >= current) || (moment() <= current)
                                }}
                            />
                        </div>

                        <div className='tw-min-w-[150px] tw-max-w-[200px] tw-flex tw-flex-col tw-gap-y-1' >
                            <Label className='mb-0' >End Date</Label>
                            <DatePicker
                                showTime={interval === 'daily' ? false : { format: 'HH' }}
                                onChange={(date, dateString) => { setDates(prev => ({ ...prev, endDate: dateString })) }}
                                format={interval === 'daily' ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:00:00'}
                                placeholder='End Date'
                                name='endDate'
                                disabledDate={(current) => {
                                    return (moment(dates?.endDate).add(-1, 'days') >= current) || (moment() <= current)
                                }}
                            />
                        </div>

                        <div>
                            <Button onClick={onSubmit} variant='indigo' size='sm' disabled={onGetConsumtionDataMutation?.isPending} >
                                Submit
                            </Button>
                        </div>
                    </div>

                    <div>
                        {
                            ((consumptionData?.lable?.length > 0 && consumptionData?.data?.length > 0)) && <div>
                                <div>
                                    <div className="tw-mb-1">
                                        <small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
                                    </div>
                                    <div className="tw-flex tw-justify-end tw-items-center">
                                        <button disabled={onGetConsumtionDataMutation?.isPending} className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1 disabled:tw-opacity-50`} onClick={zoomInEvent}><BootstrapTooltip title="ZoomIn">
                                            <div className="tw-flex tw-items-center fa-lg">
                                                <i class="fas fa-search-plus" aria-hidden="true"></i>
                                            </div>
                                        </BootstrapTooltip>
                                        </button>
                                        <button disabled={onGetConsumtionDataMutation?.isPending} className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1 disabled:tw-opacity-50`} onClick={zoomOutEvent}>
                                            <BootstrapTooltip title='ZoomOut'>
                                                <div className="tw-flex tw-items-center fa-lg">
                                                    <i class="fas fa-search-minus" aria-hidden="true"></i>
                                                </div>
                                            </BootstrapTooltip>
                                        </button>

                                        <button disabled={onGetConsumtionDataMutation?.isPending} className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2 disabled:tw-opacity-50" onClick={panLeftEvent}>
                                            <BootstrapTooltip title='Left scroll'>
                                                <div className="tw-flex tw-items-center fa-lg">
                                                    <i class="fas fa-caret-left" aria-hidden="true"></i>
                                                </div>
                                            </BootstrapTooltip>
                                        </button>
                                        <button disabled={onGetConsumtionDataMutation?.isPending} className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2 disabled:tw-opacity-50" onClick={panRightEvent}>
                                            <BootstrapTooltip title='Right scroll'>
                                                <div className="tw-flex tw-items-center fa-lg">
                                                    <i class="fas fa-caret-right" aria-hidden="true"></i>
                                                </div>
                                            </BootstrapTooltip>
                                        </button>
                                    </div>


                                </div>

                            </div>
                        }
                    </div>
                </div>

                {
                    (!isDataFetch ? "" : onGetConsumtionDataMutation?.isPending ? <GoogleLoader bodyClassName='tw-min-h-[500px]' /> :
                        onGetConsumtionDataMutation?.error ? <div className="tw-flex tw-items-center tw-justify-center py-4 tw-min-h-[500px] tw-text-2xl tw-font-extrabold">
                            Something Went Wrong
                        </div> : (consumptionData?.lable?.length > 0 && consumptionData?.data?.length > 0) ? <div className=" tw-py-4 tw-h-[600px]">
                            <Bar ref={chartRefEvent} data={consumptionDatasets} options={barChartExpandOptionsWithCtrl} />
                        </div> : <div className="tw-flex tw-items-center tw-justify-center py-4 tw-min-h-[500px] tw-text-2xl tw-font-extrabold">
                            Data Not Available
                        </div>
                    )
                }
            </Modal.Body>
        </Modal>
    );
}